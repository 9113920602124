import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DataState } from "../common-types";
import { ICondition, IDocumentList } from "./data-extraction.types";

export interface DataExtractionData {
  conditionsData: DataState<ICondition[]>;
  documentsList: DataState<IDocumentList[]>;
  isLoading: number;
  fileData: DataState<string>;
}

export const initialDataExtractionDataResponse: DataExtractionData = {
  conditionsData: { data: [], errorMessage: undefined },
  documentsList: { data: [], errorMessage: undefined },
  isLoading: 0,
  fileData: { data: "", errorMessage: undefined },
};

const featureDataExtraction = createFeatureSelector<DataExtractionData>("feature_data_extraction");
export const conditionsDataSelector = createSelector(featureDataExtraction, (state) => state.conditionsData);
export const documentsListSelector = createSelector(featureDataExtraction, (state) => state.documentsList);
export const isLoadingSelector = createSelector(featureDataExtraction, (state) => state.isLoading);
export const fileDataSelector = createSelector(featureDataExtraction, (state) => state.fileData);
